export default {
	'team-roles': {
		admin: 'Admin',
		manager: 'Manager',
		user: 'User',
	},
	error: {
		title: "You don't have access to the requested resource or it is not available yet.",
		button: 'Back to main page',
		info: 'Do you need additional help? An error occured? Contact with your manager or technical support team.',
	},
	'general-error': {
		title: 'An error occurred',
		refresh: 'Refresh',
		contact: 'Contact platform administrator for more information.',
		details: 'Details',
	},
	'landing-page': {
		link: {
			'sign-up': 'Sign Up',
			'sign-in': 'Sign In',
		},
		copyright: 'Gamfi Spółka z o.o.',
		acceptation: 'By using our app you accept the use of cookies in accordance with',
		'terms-and-conditions': {
			'cookie-policy': 'Cookie Policy',
			'privacy-policy': 'Privacy Policy',
			'terms-and-conditions': 'Terms of Conditions',
		},
	},
	auth: {
		form: {
			error: {
				required: 'This field is required.',
				'invalid-email': 'The e-mail address you entered is incorrect.',
				'invalid-identifier': 'The identifier you entered is incorrect.',
				'user-not-exists': 'The specified user or password is invalid.',
				'user-not-verified': 'The entered user is not verified.',
				'user-inactive': 'The entered user is inactive.',
				'user-not-found': 'The specified user or password is invalid.',
				'user-profile-not-active': 'The user account is inactive.',
				'invalid-password-reset-token': 'The token is invalid.',
				'invalid-password': 'The password you entered is incorrect.',
				'passwords-differ': 'This password is not identical to the previous one.',
				'invalid-input': 'Input data is incorrect.',
				'user-already-registered': 'The entered user is already registered.',
				'required-agreements': 'This agreement is required.',
				'agreements-required': 'Agreements marked with an asterisk are required.',
				'empty-password': 'Password field is required.',
				'password-minimal-length': 'Minimal required length: {length}.',
				'password-minimal-lowercase-occurance': 'Minimal required lowercase characters: {occurance}.',
				'password-minimal-uppercase-occurance': 'Minimal required uppercase characters: {occurance}.',
				'password-minimal-number-occurance': 'Minimal required number characters: {occurance}.',
				'password-minimal-special-occurance': 'Minimal required special characters: {occurance}.',
				'disabled-in-configuration': 'Integration has been disabled.',
				'user-profile-does-not-match': 'User profile does not match.',
				'email-domain-not-on-whitelist': 'Registration is not possible in this domain.',
				'not-allowed': {
					'user-registration-not-complete': 'User has not yet completed registration.',
					'user-not-verified': 'The entered user is not verified.',
				},
				'one-of-two': 'At least one of these fields is required.',
				'resource-error': 'An error occurred while logging in.',
				email: {
					'invalid-domain': 'Incorrect domain name.',
				},
				phone: {
					'invalid-format': 'Incorrect format of the phone number.',
					'invalid-length': 'Incorrect length of the phone number.',
				},
			},
		},
		login: {
			navigate: {
				'not-account-yet': "Don't have an account yet?",
				register: 'Register',
				'do-not-remember-data': 'Set or reset the password',
				'do-not-remember-identifier': 'Forgot your identifier or password?',
			},
			integration: {
				email: {
					title: 'Log in using your e-mail address',
					field: {
						email: {
							placeholder: 'Enter your e-mail address',
						},
						password: {
							placeholder: 'Enter password',
						},
					},
					button: {
						login: 'Log in',
						check: 'Log in',
					},
					status: {
						'reset-password': {
							title: 'Account activation',
							message:
								'The link to the password setting was sent to the given e-mail address. For security reasons, the link is only valid for 60 minutes.',
						},
					},
				},
				'imported-id': {
					title: 'Log in using your e-mail address or identifier',
					field: {
						identifier: {
							placeholder: 'Enter your e-mail address or identifier',
						},
						password: {
							placeholder: 'Enter password',
						},
					},
					button: {
						login: 'Log in',
						register: 'Sign up',
						check: 'Log in',
					},
					status: {
						'reset-password': {
							title: 'Account activation',
							message:
								'The link to the password setting was sent to the given e-mail address. For security reasons, the link is only valid for 60 minutes.',
						},
					},
				},
				oauth: {
					facebook: 'Log in with Facebook',
					generic: 'Log in with an external system',
					google: 'Log in with Google',
					'linked-in': 'Log in with LinkedIn',
					error: {
						title: 'An unexpected error occurred while logging in',
						type: {
							general: 'The OAuth login has been interrupted.',
							'user-already-registered': 'The user with the specified e-mail address already exists.',
							'invalid-one-time-token': 'The token entered is incorrect.',
							'not-allowed': {
								'connect-profiles-before-authentication':
									'The e-mail assigned to the integration account is already in our database. Log in to the platform using your e-mail address.',
							},
						},
					},
					return: 'Click here to return to the homepage.',
					loading: 'Logging in, please wait...',
				},
			},
		},
		register: {
			navigate: {
				'already-have-account': 'Already have an account?',
				login: 'Log in',
			},
			integration: {
				email: {
					title: 'Sign up with your email address',
					field: {
						email: {
							placeholder: 'Enter your e-mail address',
						},
					},
					button: 'Register',
					status: {
						ok: {
							title: 'Your account has been registered',
							message: 'Check your mailbox, you will find there an e-mail with an activation link.',
						},
					},
				},
				'imported-id': {
					title: 'Sign up process',
					field: {
						forename: {
							placeholder: 'Enter forename',
						},
						surname: {
							placeholder: 'Enter surname',
						},
						email: {
							placeholder: 'Enter e-mail',
						},
						phone: {
							placeholder: 'Enter phone',
						},
					},
					button: {
						register: 'Sign up',
					},
					status: {
						'email-sent': {
							title: 'Resetting the password',
							message:
								'The link to the password setting was sent to the given e-mail address. For security reasons, the link is only valid for 60 minutes.',
						},
					},
				},
				oauth: {
					facebook: 'Sign up with Facebook',
					generic: 'Sign up with an external system',
					google: 'Sign up with Google',
					'linked-in': 'Sign up with LinkedIn',
				},
				token: {
					title: 'Create account',
					submit: 'Sign up',
					error: {
						'no-token': 'Incorrect token',
					},
					status: {
						ok: {
							title: 'Your account has been registered',
						},
					},
				},
			},
		},
		'reset-password': {
			navigate: {
				'remember-password': 'Do you remember your password?',
				login: 'Log in',
			},
			integration: {
				email: {
					title: 'Set or reset the password',
					instruction: {
						text: 'Enter your {email}, and we will send you an email with a link to set your password.',
						email: 'email address',
					},
					field: {
						email: {
							placeholder: 'Enter your e-mail address',
						},
					},
					button: 'Send',
					status: {
						ok: {
							title: 'Resetting the password',
							message:
								'The link to the password setting was sent to the given e-mail address. For security reasons, the link is only valid for 60 minutes.',
						},
					},
				},
				'email-annoying': {
					status: {
						ok: {
							title: 'Resetting the password',
							message:
								'If the given e-mail address is in the user database, a link to the password setting has been sent.',
						},
					},
				},
				'imported-id': {
					title: 'Resetting the password',
					instruction: {
						text:
							'Enter your {identifier} that you used to register. We will send you an email with a link or authorization code in the SMS to reset your password.',
						identifier: 'e-mail address or identifier',
					},
					field: {
						identifier: {
							placeholder: 'Enter your e-mail address od identifier',
						},
					},
					button: 'Send',
					status: {
						'email-sent': {
							title: 'Resetting the password',
							message:
								'The link to the password setting was sent to the given e-mail address. For security reasons, the link is only valid for 60 minutes.',
						},
					},
				},
			},
			action: {
				'verify-token': {
					title: 'There was a problem with the received link',
					message: 'Your link is no longer valid or is incorrect',
					'reset-link': '{link} and then provide your email address to generate a new link.',
					'reset-link-text': 'Click here',
				},
				'set-password': {
					title: {
						reset: 'Set your new password',
						activate: 'Activate your account by setting your password',
					},
					field: {
						password: {
							placeholder: 'Enter your password',
						},
						'confirm-password': {
							placeholder: 'Repeat the password',
						},
					},
					button: {
						reset: 'Set new password',
						activate: 'Activate account',
					},
				},
				'authorize-code': {
					title: {
						reset: 'Enter authorization code',
					},
					field: {
						'authorization-code': {
							placeholder: 'Enter your authorization code',
						},
					},
				},
			},
		},
	},
	'achievement-list': {
		title: 'Achievements',
		subtitle: 'description',
		'all-tab': 'All',
		'section-title': {
			progress: 'Your progress',
			'gain-info': 'For this achievement you will receive:',
		},
		content: {
			'progress-description': 'From the available achievements in the category "All" you managed to get:',
			requirements: 'Requirements',
		},
		error: {
			empty: 'Achievement list is empty.',
		},
	},
	'achievement-details': {
		'section-title': {
			'achievement-description': 'Description of achievement:',
			requirements: 'Achievement requirements:',
		},
	},
	faq: {
		title: 'FAQ',
		subtitle: 'Description',
		'all-tab': 'All',
		content: {
			category: 'Category:',
		},
		error: {
			empty: 'FAQ is empty.',
		},
	},
	'mission-list': {
		title: 'Missions',
		content: {
			'tasks-execution': 'Task {completed} of {all}',
			'earnings-information': {
				mission: 'You will receive for completing all tasks:',
				task: 'You will receive for this task:',
			},
		},
		button: {
			do: 'Execute',
		},
		error: {
			empty: 'Mission list is empty.',
		},
	},
	'news-details': {
		subtitle: 'Added:',
		'section-title': {
			comments: 'Comments',
		},
	},
	'news-list': {
		title: 'News',
		subtitle: 'description',
		content: {
			added: 'Added',
		},
		error: {
			empty: 'News list is empty.',
		},
	},
	'notification-list': {
		title: 'Notifications',
		subtitle: 'description',
		'all-tab': 'All',
		error: {
			empty: 'Notification list is empty.',
		},
	},
	'prize-list': {
		title: 'Awards',
		subtitle: 'description',
		'all-tab': 'All',
		'section-title': {
			'items-in-your-range': 'Rewards in your reach',
		},
		button: {
			'buy-now': 'Order now',
			'check-out': 'See',
			soon: 'Soon',
		},
		content: {
			amount: 'Quantity',
			count: '{remaining} out of {start}',
			'availability-items': 'Available pieces',
			'sold-out': 'Sold out',
			'from-date': 'From',
			'missing-points': 'You are missing:',
			price: 'Price',
		},
		error: {
			empty: 'No prizes in the category.',
		},
	},
	'prize-details': {
		'section-title': {
			'prize-description': 'Description',
			price: 'Price:',
			'available-currently': 'Currently available',
		},
		button: {
			'buy-now': 'Order now',
			confirm: 'Confirm',
			resign: 'Resign',
			soon: 'Soon',
		},
		content: {
			'not-available': 'Prize not available!',
			missing: 'You are missing:',
			calendar: {
				monday: 'mon',
				tuesday: 'tue',
				wednesday: 'wed',
				thursday: 'thu',
				friday: 'fri',
				saturday: 'sat',
				sunday: 'sun',
				'info-cloud': {
					'available-on-day': 'Prizes available on day:',
					'not-available': 'The prize is unavailable on this day',
				},
			},
			'buy-item': {
				'confirm-purchase': 'Order now',
				cancel: 'Cancel',
				close: 'Close',
				ordered: 'Your order has been placed. Thank You!',
				error: 'Error has occurred',
				address: {
					create: 'Create a new address...',
				},
			},
			'sold-out': 'Sold out',
		},
	},
	ranking: {
		title: 'Ranking',
		subtitle: 'description',
		content: {
			'your-place': {
				point__user: 'Your place',
				user_imported: 'Your place',
				commitment__user: 'Your place',
				point__team: "Your team's place",
				team_imported: "Your team's place",
				point__container: '{rankingName} - Your place',
				container_imported: '{rankingName} - Your place',
			},
			button: {
				'log-in': 'Log in',
			},
		},
		error: {
			empty: 'Ranking is empty',
		},
	},
	'task-details': {
		'collection-mission': {
			title: '{name}',
			button: {
				repeat: 'Repeat',
				do: 'Execute',
			},
			header: {
				'your-progress': 'Your progress:',
				'progress-status': '{completed} of {all}',
			},
			content: {
				'gain-for-complete-mission': 'You will receive for completing all tasks:',
				'gain-for-complete-task': 'You will receive for this task:',
				'new-tasks': `{
					newTasks,
					plural,
					one {New task}
					other {New tasks}
				}`,
				'done-tasks': `{
					doneTasks,
					plural,
					one {Task completed}
					other {Completed tasks}
				}`,
			},
		},
		graph: {
			content: {
				progress: 'Your progress in the mission',
				progressStatus: '{completed} of {all}',
			},
		},
		mockup: {
			button: {
				leave: 'Exit',
				continue: 'Continue',
			},
			content: {
				'answer-status': {
					'wrong-answer': 'You did not answer the question correctly - try again',
					'correct-answer': 'Task completed',
					'try-again-in': 'Try again for:',
					gaining: 'You get',
				},
			},
		},
		'complete-phrase-question': {
			content: {
				'task-description': 'Your task is to fill in the missing words in the fields.',
			},
		},
		declaration: {
			content: {
				'task-description': 'Your task is to declare the execution of the following actions.',
			},
		},
		'guess-phrase-question': {
			input: {
				'enter-answer': 'Enter the answer here',
			},
			content: {
				'task-description': 'Your task is to enter the correct answer.',
			},
		},
		'retype-phrase-question': {
			content: {
				'task-description': 'Your task is to rewrite indicated words.',
			},
		},
		'visit-link': {
			content: {
				'instruction-after': 'The task was to visit the site:',
				'instruction-before': 'Your task is to review the content. Click "Execute" to complete the task.',
			},
		},
		document: {
			button: {
				'download-pdf': 'Download PDF',
			},
		},
		'true-false': {
			button: {
				'true-claim': 'True',
				'false-claim': 'False',
			},
		},
		join: {
			button: {
				join: 'Join',
			},
			'is-a-member-already': {
				description: 'You have already chosen:',
			},
			'is-anonymous': {
				description: 'You cannot complete this task because:',
				title: 'You are not logged in!',
				'click-button': 'Click the button below to participate completely in the game.',
				'button-text': 'Register',
			},
			'join-task-crashed': {
				description: 'A crash has occurred!',
				title: 'Please inform us of the error',
			},
			'team-join-view': {
				title: 'Select below',
			},
			'user-container-join-view': {
				title: 'Select below',
			},
		},
		'user-profile-fields': {
			instruction: 'Please, complete all required fields and click "Confirm".',
		},
		common: {
			button: {
				continue: 'Continue',
				check: 'Check',
				do: 'Execute',
				'do-again': 'Repeat',
				leave: 'Exit',
				confirm: 'Confirm',
				help: 'Help',
			},
			content: {
				'answer-status': {
					'remaining-static': 'Left:',
					remaining: `To complete the task {
						seconds,
						plural,
						one {remaining}
						other {remaining}
					} `,
					'remaining-seconds': `{
						seconds,
						plural,
						one {second}
						other {seconds}
					} `,
					'already-completed': 'This task has already been done!',
					'wrong-answer-try-again': 'You did not answer the question correctly - try again',
					'wrong-answer': 'Sorry, wrong answer',
					'correct-answer': 'Task completed',
					'try-again-in': 'Try again in:',
					gaining: 'You get',
					crashed: 'An unexpected error occurred. Please try again.',
					awaiting: 'The task will be completed at the moment of acceptance by the team manager.',
					rejected: 'Task is not completed!',
					'rejected-reason': 'Task is not completed! {reason}',
				},
				repetition: {
					'repetition-time-last': 'You can repeat this task in:',
					'repetition-approval': 'You can repeat the task',
					'gained-points': 'Points scored',
					'completion-time': 'Runtime',
					completion: 'performance',
					days: `{
						days,
						plural,
						one {day}
						other {days}
					} `,
				},
			},
		},
	},
	'user-profile': {
		field: {
			mapped: {
				forename: 'Forename',
				surname: 'Surname',
				nickname: 'Nickname',
				description: 'Description',
				email: 'E-mail',
				phone: 'Phone',
			},
		},
	},
	'user-details': {
		'notification-tab': 'Notifications',
		button: {
			'mobile-mode': {
				edit: 'Save',
				preview: 'Edit',
			},
			mode: {
				edit: 'Save changes',
				preview: 'Edit profile',
			},
			edit: 'Edit',
			'choose-photo': 'Select image',
		},
		content: {
			'user-data': {
				position: 'Position:',
				'last-activity': 'Last activity',
			},
			level: 'Level',
			'remaining-signs': 'Characters left:',
			'edit-in-progress': 'Editing profile',
			editField: {
				nickname: 'Nickname',
				description: 'Description',
			},
		},
		tabs: {
			informations: 'Informations',
			notifications: 'Notifications',
		},
		agreements: {
			title: 'Your agreements',
			gdpr: {
				title: 'GDPR',
				warning:
					'If you cancel your consent, we will delete your account in the application and delete your personal data, points earned and progress in the tasks completion. A deleted account cannot be restored. However, you can create a new one.',
			},
			required: 'Required',
			optional: 'Optional',
			confirm: 'I confirm the changes.',
			saveButton: 'Save',
			'accept-requirements': 'You must confirm the changes',
			'your-agreements': 'Your agreements',
		},
	},
	'team-details': {
		button: {
			'mobile-mode': {
				edit: 'Save',
				preview: 'Edit',
			},
			mode: {
				edit: 'Save changes',
				preview: 'Edit profile',
			},
			edit: 'Edit',
		},
		content: {
			'remaining-signs': 'Characters left:',
			'edit-in-progress': 'Editing profile',
		},
		tabs: {
			members: 'Members',
			tasks: 'Tasks',
			settings: 'Settings',
		},
		tasks: {
			status: {
				awaiting: 'completed task {task}. Is it true? Decide!{br}on {date}',
				accepted: 'completed task {task}{br}on {date}',
				rejected: "didn't complete task {task}{br}on {date}",
			},
			button: {
				confirm: 'Confirm',
				reject: 'Reject',
			},
			'rejection-modal': {
				title: 'Reject task',
				'reason-placeholder': 'Rejection reason (optional)',
				button: 'Reject',
			},
		},
	},
	'create-submission': {
		return: {
			or: 'Click ',
			link: 'here',
			rest: ' for change submission type!',
		},
		form: {
			error: {
				'too-short': 'The minimum number of characters is: {value}',
				'too-long': 'The maximum number of characters is: {value}',
				required: 'This field is required!',
			},
		},
		confirmation: {
			title: 'The submission has been added',
			button: 'Close',
			content: {
				'line-1': 'Your submission has been added and is in moderation.',
				'line-2': 'We will inform you about the status change.',
			},
		},
		error: {
			title: 'An unexpected error has occurred',
			content: 'Try again in a few moments.',
			button: 'Close',
		},
		'image-submission': {
			title: 'Your submission',
			form: {
				title: {
					placeholder: 'Enter title',
				},
				file: 'click here to add picture',
				button: 'Add submission',
			},
		},
		'submission-type-selection': {
			title: 'Take part in the challenge',
			subtitle: 'Select the type of submission:',
			type: {
				text: 'Add text',
				image: 'Add picture',
			},
		},
		'text-submission': {
			title: 'Your submission',
			form: {
				title: {
					placeholder: 'Enter title',
				},
				text: {
					placeholder: 'Enter text',
				},
				button: 'Add submission',
			},
		},
	},
	'challenge-list': {
		title: 'Challenges',
		subtitle: 'description',
		loading: 'Challenge list loading in progress...',
		tabs: {
			open: 'Ongoing',
			future: 'Incoming',
			finished: 'Finished',
			all: 'All',
		},
		content: {
			'challenge-status': {
				notStarted: 'Time to start: {remainingTime}',
				inProgress: 'In progress:',
				beforeResolve: 'Waiting for results',
				finished: 'Finished',
			},
			'reverse-status': {
				notStarted: 'Starts in',
				inProgress: 'Remains',
				beforeResolve: 'Results announcement in',
			},
			earnings: 'Point to earn',
		},
		button: {
			takePart: 'Take part',
		},
		error: {
			empty: 'Challenge list is empty. Come back soon, okay?',
		},
		type: {
			user: 'Individual',
			team: 'Team',
			'container-team': 'Based on the structure - team',
			'container-user': 'Based on the structure - individual',
		},
	},
	'challenge-details': {
		creative: {
			type: {
				image: 'Image',
				text: 'Text',
				video: 'Video',
				gif: 'Gif',
			},
			'section-title': {
				'challenge-info': 'Details',
			},
			button: {
				'take-part': 'Take part',
				'add-submission-in': 'Add a new submission in',
				'limit-reached': 'Your submission limit has been reached',
				'only-for-manager': 'Only for team manager',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Challenge type',
					'creative-type': 'Creative',
					'submission-type': 'Type of submission',
					'submission-types': {
						video: 'Video',
						image: 'Image',
						text: 'Text',
						gif: 'Gif',
					},
					'participation-mode': 'Type of participation',
					'submissions-limit': 'Submission limit',
					'points-for-added-submission': 'Points for sending submission',
					'points-for-accepted-submission': 'Points for accepted submission',
				},
				'not-selected-winners': "Challenge hasn't been resolved yet",
				remains: 'Remaining',
			},
			'menu-tabs': {
				details: 'Details',
				'my-submissions': 'My submission',
				'recently-added-submissions': 'Recently added',
				ranking: 'Ranking',
				prizes: 'Awards',
				winners: 'Winners',
			},
			'submission-details': {
				author: 'Author:',
				date: 'Date:',
			},
			'submission-list': {
				error: {
					empty: {
						user: 'You haven\'t added anything yet. Change it, click "Take part"! ;-)',
						common: 'Approved submissions will appear here.',
					},
					'publication-at': {
						'line-1': 'Added submissions will be published on {date}.',
						'line-2': 'Don\'t get left behind, add your submission now - click "Take part"!',
					},
				},
				content: {
					'submission-status': {
						rejected: 'Submission rejected',
						accepted: 'Submission accepted',
						new: 'Submission is awaiting moderation',
						'reject-reason': 'Reason for rejection: {reason}',
					},
					remains: 'Remaining: {remaining-time}',
					removed: 'User resigned from the game and withdrew consent to the publication of content',
				},
				button: {
					'remove-submission': 'Delete this submission',
				},
			},
			popup: {
				'choose-submission-type': {
					title: 'Take part in the challenge',
					subtitle: 'Available types of challenges:',
					button: {
						'add-gif': 'Add gif',
						'add-video': 'Add video',
						'add-text': 'Add text',
						'add-image': 'Add picture',
						'add-link': 'Add link',
					},
				},
				'add-submission-confirmation': {
					title: 'Submission added!',
					button: {
						close: 'Go back',
					},
					content: {
						'already-added': 'Your submission has been added and is in moderation',
						'status-change-info': 'We will inform you of the status change.',
					},
				},
				'link-submission': {
					title: 'Add a link in the challenge subject',
					button: {
						return: 'Go back',
					},
					content: {
						'return-to-choose-submission': 'or {return} to change the type of submission!',
					},
				},
				'gif-submission': {
					title: 'Add a gif in the challenge subject',
					subtitle: 'Start looking for gifs',
					button: {
						'press-to-add': 'Click here to add a gif',
						return: 'Go back',
					},
					content: {
						'return-to-choose-submission': 'or {return} to change the type of submission!',
					},
				},
				'video-submission': {
					title: 'Add a movie in the challenge subject',
					button: {
						'press-to-add': 'Click here to add a movie',
						return: 'Go back',
						continue: 'Continue',
					},
					content: {
						'or-paste-video-link': 'or paste the link to the video',
						'return-to-choose-submission': 'or {return} to change the type of submission!',
					},
				},
				'image-submission': {
					title: 'Add a photo in the challenge subject',
					button: {
						'press-to-add': 'Click here to add a photo',
						return: 'Go back',
						continue: 'Continue',
					},
					content: {
						'return-to-choose-submission': 'or {return} to change the type of submission!',
					},
				},
				'text-submission': {
					title: 'Write text in the challenge subject',
					button: {
						continue: 'Continue',
						return: 'Go back',
						or: 'or',
					},
					content: {
						'remaining-signs': 'Characters left:',
						'return-to-choose-submission': 'or {return} to change the type of submission!',
					},
					error: 'To continue, add the content to the challenge.',
				},
				'submission-summary': {
					title: 'Summary',
					subtitle: 'Name your submission in the challenge',
					button: {
						add: 'Ok, I add',
						return: 'Go back',
					},
					content: {
						'remaining-signs': 'Characters left:',
						'return-to-choose-submission': 'or {return} to change the type of submission!',
					},
					error: 'To continue please add title of the work.',
				},
				'submission-details': {
					button: {
						'add-comment': 'Add comment',
						answer: 'Reply',
					},
					'section-title': {
						comments: 'Comments',
						'add-comment': 'Add comment',
					},
					content: {
						wrote: 'Wrote',
						'remaining-signs': 'Characters left',
						status: 'Status:',
						submissionAdded: 'Added: ',
						'current-status': {
							new: 'Waiting for moderation',
							accepted: 'Accepted',
							rejected: 'Rejected',
						},
					},
				},
			},
		},
		kpi: {
			'section-title': {
				'challenge-info': 'Challenge info',
			},
			button: {
				'take-part': 'Take part',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Challenge type',
					'kpi-type': 'KPI',
					'submission-type': 'Type of submission',
					'participation-mode': 'Type of participation',
					'submissions-limit': 'Submissions limit',
					'last-import-date': 'Last update',
				},
				remains: 'Remaining: {remaining-time}',
			},
			'menu-tabs': {
				ranking: 'Ranking',
				details: 'Details',
				prizes: 'Awards',
			},
		},
		target: {
			'section-title': {
				'challenge-info': 'Details',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Challenge type',
					'creative-type': 'Target',
					'participation-mode': 'Type of participation',
					'submission-count': 'Completed',
					'submissions-limit': 'Limit',
					'points-for-accepted-submission': 'Points for submission',
					completed: 'Completed:',
				},
			},
			'menu-tabs': {
				details: 'Szczegóły',
			},
		},
		common: {
			ranked: {
				place: 'Place {placeFrom}',
				'place-range': 'Places from {placeFrom} to {placeTo}',
			},
			prizes: {
				'no-prize-set': 'No prizes.',
				points: 'Points',
			},
		},
	},
	cafeteria: {
		title: 'Reward cafeteria',
		error: {
			empty: 'Reward cafeteria is empty.',
			'internal-server-error': 'An error occurred while displaying the cafeteria.',
			'access-denied': "You don't have access to the cafeteria.",
			'disabled-in-configuration': 'Reward cafeteria is disabled.',
			'no-budget': 'Unfortunately, this item is no longer available.',
			'too-few-points': 'Insufficient funds. Purchase impossible.',
			'invalid-input': 'The data you entered is not valid.',
			'item-sold-out': 'Item sold out.',
		},
		filter: {
			price: 'Price',
			afford: 'Show me the prizes I can afford.',
			from: 'from',
			to: 'to',
		},
		item: {
			'buy-now': 'Buy now',
			'not-available': 'Not available',
			missing: 'You are missing',
			price: 'Price',
		},
	},
	notification: {
		static: {
			messages: {
				submission: {
					new: 'You added the submission "{submissionTitle}" to the challenge "{challengeTitle}"',
					rejected:
						'Your submission "{submissionTitle}" in challenge "{challengeTitle}" was rejected because: {reason}',
					'rejected-without-reason':
						'Your submission "{submissionTitle}" in challenge "{challengeTitle} "has been rejected',
					accepted: 'Your submission "{submissionTitle}" in challenge "{challengeTitle}" has been accepted',
					awardedPlace: 'You won a prize for getting a place {awardedPlace} in challenge "{challengeTitle}".',
					addedSubmission:
						'Your submission "{submissionTitle}" in challenge "{challengeTitle}" has been added.',
				},
				'declaration-task': {
					accepted: 'Your submission in task "{taskName}" has been accepted',
					'rejected-reason': 'Your submission in task "{taskName}" has been rejected',
					rejected: 'Your submission in task "{taskName}" has been rejected',
				},
				email: {
					'remember-pass': 'Emailing: Password reset',
					'reset-pass': 'Emailing: Password reset',
					registration: 'Sending an e-mail: Account registration',
					'submission-rejected': 'Sending email: the submission has been rejected',
					'submission-accepted': 'Sending email: the submission has been accepted',
				},
				bonusTransaction: 'Change in points',
				task: '{name}',
				item: '{name}',
				levelUp: {
					withName: 'Level {levelNumber} - {name}',
					withoutName: 'Level {levelNumber}',
				},
				achievement: '{name}',
				challenge: {
					target: '{name}',
				},
			},
			dates: {
				task: {
					'task-completed': 'The task was done: {date}',
					'mission-completed': 'Mission accomplished: {date}',
					'task-progress': 'Further progress has been made: {date}',
				},
				achievement: 'The award was earned: {date}',
				item: 'Item purchased: {date}',
				email: 'E-mail was received: {date}',
				submission: {
					new: 'Submission added: {date}',
					accepted: 'The submission was accepted: {date}',
					rejected: 'The submission was rejected: {date}',
					awardedPlace: 'Place awarded: {date}',
				},
				'declaration-task': {
					accepted: 'The submission was accepted: {date}',
					rejected: 'The submission was rejected: {date}',
				},
				bonusTransaction: 'Points recalculated: {date}',
				challenge: {
					target: 'Completed: {date}',
				},
			},
			'null-states': {
				task: '[Task deleted]',
				achievement: '[Achievement removed]',
				item: '[Subject removed]',
				submission: '[Submission removed]',
				awardedPlace: '[Job deleted]',
			},
		},
		'real-time': {
			achievement: {
				title: 'Congratulations!',
				subtitle: "You've earned an achievement",
				button: {
					default: 'Collect the prize',
					'without-earnings': 'Continue',
				},
				'achievement-unlocked': 'Achievement unlocked!',
				'earn-achievement': 'You get an achievement',
				earn: 'You earn',
			},
			challengeSubmissionStateChanged: {
				title: 'Congratulations!',
				subtitle: 'Submission state changed',
				button: {
					default: 'Continue',
					'without-earnings': 'Continue',
				},
				earn: 'You earn',
			},
			declarationTaskAcceptanceChanged: {
				title: 'Congratulations!',
				subtitle: 'Task state changed',
				button: {
					default: 'Continue',
					task: 'Check why',
				},
				earn: 'You earn',
			},
			task: {
				title: 'Congratulations!',
				subtitle: 'You have accomplished the task',
				button: {
					default: 'Receive points',
					'without-earnings': 'Continue',
					nextTask: 'Continue',
				},
				congratulations: 'Congratulations!',
				'for-execution': 'For completing the task you get:',
			},
			levelUp: {
				title: 'Congratulations!',
				subtitle: 'You have reached a new level',
				button: {
					default: 'Continue',
					'without-earnings': 'Continue',
				},
				earn: 'You earn',
			},
			mission: {
				title: 'Congratulations!',
				subtitle: 'Mission completed',
				button: {
					default: 'Collect the prize',
					'without-earnings': 'Continue',
					nextTask: 'Continue',
				},
				congratulations: 'Congratulations!',
				'for-execution': 'For completing the mission you get:',
				earn: 'For completing the mission you get:',
			},
		},
	},
	currency: {
		bucks: `{
			bucks,
			plural,
			one {bucks}
			other {bucks}
		} `,
		exp: `{
			exp
			plural,
			one {point}
			other {points}
		} experience`,
	},
	graph: {
		tooltip: {
			mission: {
				tasks: 'Tasks',
				progress: '{completed} of {all}',
			},
			button: {
				repeat: 'Repeat',
				execute: 'Execute',
			},
		},
	},
	loader: {
		text: ['Loading user settings...'],
		loading: {
			settings: 'Downloading the application...',
		},
	},
	time: {
		days: `{
			time,
			plural,
			one {# day}
			other {# days}
		}`,
		hours: `{
			time,
			plural,
			one {# hour}
			other {# hours}
		}`,
		minutes: `{
			time,
			plural,
			one {# minute}
			other {# minutes}
		}`,
		seconds: `{
			time,
			plural,
			one {# second}
			other {# seconds}
		}`,
	},
	markdown: {
		'show-more': '(show more)',
		'show-less': '(show less)',
	},
	'terms-and-conditions': {
		'terms-and-conditions': 'Terms of Conditions',
		'privacy-policy': 'Privacy Policy',
		'cookie-policy': 'Cookie Policy',
	},
	'contact-list': {
		title: 'Contacts',
		search: {
			placeholder: 'Search...',
		},
		tab: {
			all: 'All',
			active: 'Active',
		},
		error: {
			empty: 'No results.',
			'internal-server-error': 'An error occurred while displaying the contact list.',
			'access-denied': "You don't have access to the contact list.",
			'disabled-in-configuration': 'Contact list is disabled.',
		},
		pagination: {
			of: 'of',
		},
	},
	// clear translations:
	app: {
		installation: {
			title: 'Install the application on your device',
			subtitle: 'Add the app to your home screen{separator}for quick and easy access.',
			button: 'Click to install',
		},
	},
	// reusable components translations:
	common: {
		'go-back': 'Go back',
		form: {
			block: {
				fileUpload: {
					placeholder: 'Select file...',
					chooseButton: 'Choose a file',
					deleteButton: 'Delete',
					processing: 'Processing...',
					addAnotherFile: 'Add another file',
				},
				dropdownDefault: 'Select...',
			},
		},
		view: {
			list: {
				search: {
					placeholder: 'Search...',
				},
			},
		},
	},
	component: {
		form: {
			'setting-field': {
				'click-here': 'Click here',
				'click-here-to-set': 'Click here to set...',
			},
		},
	},
	form: {
		error: {
			required: 'Field is required.',
			'check-required': 'Declaration is required.',
			'too-short': 'Too short (min {min} chars).',
			'too-long': 'Too long (max {max} chars).',
			'invalid-email': 'E-mail address is invalid.',
			'invalid-url': 'URL address is invalid.',
			'invalid-postal-code': 'Postal code is invalid.',
			'invalid-mobile-phone': 'Mobile phone is invalid.',
			'min-elements': 'Not enough answers (min {min}).',
		},
	},
	portal: {
		title: "Hello {name}, here's your plan",
		process: {
			placeholder: "Wow, so that's what it looks like behind all those tasks. Cool!",
			title: {
				duration: `Process ends in: <span class="processList__text--highlighted">{
						duration,
						plural,
						one {#&nbsp;day}
						other {#&nbsp;days}
					}</span>.&nbsp;`,
				overdue: '<span class="processList__text--highlighted">Tasks overdue! Complete them ASAP.</span>&nbsp;',
				'overdue-for-manager':
					'<span class="processList__text--highlighted">The deadline for completing the process has already passed.</span>&nbsp;',
				date: {
					'first-day': `Starting the first day in: <span class="processList__text--highlighted">{
						time,
						plural,
						one {#&nbsp;day}
						other {#&nbsp;days}
					}</span>.`,
					'key-date': `Launching the process in: <span class="processList__text--highlighted">{
						time,
						plural,
						one {#&nbsp;day}
						other {#&nbsp;days}
					}</span>.`,
					'last-day': `Time left until the last day: <span class="processList__text--highlighted">{
						time,
						plural,
						one {#&nbsp;day}
						other {#&nbsp;days}
					}</span>.`,
				},
			},
			report: {
				downloadReport: 'Download report',
				button: {
					processReport: 'Processes',
					processActionsReport: 'Actions',
				},
			},
			filter: 'Filter',
			'filter-all': 'All',
		},
		action: {
			type: {
				task: 'Task',
				mission: 'Mission',
				form: 'Form',
			},
			deadline: {
				general: '{time} to {type}',
				overdue: 'The deadline has passed {time} ago.',
				'solidsnake-task': 'complete',
				'solidsnake-mission': 'complete',
				'solidsnake-form': 'complete',
				form: 'complete',
				story: 'complete',
				page: 'complete',
			},
			placeholder: {
				messages: {
					1: 'Task list cleared, all done for today!',
					2: "Houston, we have no more tasks! Let's call it a day.",
					3: 'New tasks are on the way so just keep on swimming!',
					4: "If tasks are like clouds, today's a sunny day! Go ahead, use it!",
					5: "Abra cadabra! And all those tasks vanished! Don't worry, there will be more!",
					6: "There are no more tasks here. But we have a cool cactus. Look at it, it's so cool!",
					7: 'No need to dig deeper, there are no task here, for now.',
				},
				nextAction: 'Expect more tasks on <span class="color-blue font-weight-normal">{date}</span>',
				nextActionUnknownDate: 'Expect more tasks soon!',
			},
			'assign-role': {
				placeholder: 'Assign role {roleName}',
				confirm: 'Confirm',
			},
		},
		tasks: {
			title: 'Your plan',
		},
		contacts: {
			title: 'Contacts',
			roleTitle: 'Role',
			role: {
				manager: 'Manager',
			},
		},
		employees: {
			yours: 'Your team',
		},
	},
};
