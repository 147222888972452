import React from 'react';
import classnames from 'classnames';
import AssignRoleAction from 'models/onboarding/process/action/AssignRoleAction';
import TaskAction from 'models/onboarding/process/action/TaskAction';
import { NavLink } from 'react-router-dom';
import ConditionalLink from 'components/_/common/link/ConditionalLink';

export type ActionViewProps = {
	url?: string;
	action: AssignRoleAction | TaskAction;
	disableLink?: boolean;
	isDelayed?: boolean;
	icon?: React.ReactElement | false;
	content?: React.ReactElement;
	description: React.ReactElement;
	contentImage?: string | null;
};

export const ActionView = ({
	url,
	action,
	disableLink,
	isDelayed,
	icon,
	contentImage,
	content,
	description,
}: ActionViewProps) => {
	return (
		<ConditionalLink
			as={['form', 'story', 'page'].includes(action.contentType) ? 'a' : NavLink}
			to={url}
			onClick={event => disableLink && event.preventDefault()}
		>
			<div
				className={classnames('mb-2 action d-flex flex-column flex-sm-row', {
					'action--delayed': isDelayed,
					'action--withImage': !!contentImage,
				})}
			>
				{contentImage && (
					<div
						className="action__image background-image size--cover flex-grow"
						style={{
							backgroundImage: `url(${contentImage})`,
						}}
					/>
				)}
				<div className="actionList__listRow">
					<div className="d-flex flex-column h-100 pb-3 fs-4">
						<div className="d-flex h-100 px-3 px-sm-5 pt-3 pt-sm-0">
							{icon && !contentImage && (
								<div className="d-flex align-items-center mr-3 mr-sm-5 align-self-start pt-sm-3">
									<div className="action__icon">{icon}</div>
								</div>
							)}
							<div className="d-flex justify--center flex-column pt-0 pt-sm-3 w-100">
								{content}
								<div
									className={classnames({
										'mt-sm-2': content,
									})}
								>
									{description}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ConditionalLink>
	);
};

export default ActionView;
