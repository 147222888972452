import avatar from 'assets/images/icons/sectionIcons/Avatar.png';
import bell from 'assets/images/icons/sectionIcons/Bell.png';
import chart from 'assets/images/icons/sectionIcons/Chart.png';
import doc from 'assets/images/icons/sectionIcons/Doc.png';
import done1 from 'assets/images/icons/sectionIcons/Done-1.png';
import done2 from 'assets/images/icons/sectionIcons/Done-2.png';
import done3 from 'assets/images/icons/sectionIcons/Done-3.png';
import done4 from 'assets/images/icons/sectionIcons/Done-4.png';
import done5 from 'assets/images/icons/sectionIcons/Done-5.png';
import envelope from 'assets/images/icons/sectionIcons/Envelope.png';
import folderLocker from 'assets/images/icons/sectionIcons/Folder-locker.png';
import folderUser from 'assets/images/icons/sectionIcons/Folder-user.png';
import folder from 'assets/images/icons/sectionIcons/Folder.png';
import heart from 'assets/images/icons/sectionIcons/Heart.png';
import hello from 'assets/images/icons/sectionIcons/Hello.png';
import locker from 'assets/images/icons/sectionIcons/Locker.png';
import mapPin from 'assets/images/icons/sectionIcons/Map-pin.png';
import photo from 'assets/images/icons/sectionIcons/Photo.png';
import picture from 'assets/images/icons/sectionIcons/Picture.png';
import roadPin from 'assets/images/icons/sectionIcons/Road-pin.png';
import settings from 'assets/images/icons/sectionIcons/Settings.png';
import star from 'assets/images/icons/sectionIcons/Star.png';
import userCard from 'assets/images/icons/sectionIcons/User-card.png';
import users from 'assets/images/icons/sectionIcons/Users.png';

export default {
	avatar,
	bell,
	chart,
	doc,
	'done-1': done1,
	'done-2': done2,
	'done-3': done3,
	'done-4': done4,
	'done-5': done5,
	envelope,
	'folder-locker': folderLocker,
	'folder-user': folderUser,
	folder,
	heart,
	hello,
	locker,
	'map-pin': mapPin,
	photo,
	picture,
	'road-pin': roadPin,
	settings,
	star,
	'user-card': userCard,
	users,
};
